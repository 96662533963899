import React from "react"

function Glogo() {
  return (
    <svg
      width='157'
      height='51'
      viewBox='0 0 157 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_217)'>
        <path
          d='M152.11 31.1682L156.466 34.0427C155.052 36.104 151.671 39.6406 145.825 39.6406C138.567 39.6406 133.161 34.0807 133.161 27.0078C133.161 19.4813 138.624 14.3752 145.214 14.3752C151.843 14.3752 155.09 19.5945 156.141 22.4123L156.714 23.8497L139.637 30.8467C140.935 33.3809 142.96 34.6669 145.825 34.6669C148.691 34.6669 150.677 33.2674 152.11 31.1682ZM138.72 26.611L150.124 21.9208C149.493 20.3514 147.621 19.2357 145.386 19.2357C142.54 19.2357 138.586 21.732 138.72 26.611Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M124.927 1.49686H130.428V38.487H124.927V1.49655V1.49686Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M116.255 15.3586H121.565V37.825C121.565 47.148 116.007 50.987 109.435 50.987C103.246 50.987 99.5216 46.8645 98.1269 43.5173L102.998 41.5125C103.876 43.5737 105.997 46.0135 109.435 46.0135C113.657 46.0135 116.255 43.4226 116.255 38.5815V36.7661H116.064C114.803 38.2788 112.396 39.6404 109.34 39.6404C102.96 39.6404 97.1141 34.1372 97.1141 27.0458C97.1141 19.9161 102.96 14.3565 109.34 14.3565C112.377 14.3565 114.803 15.6992 116.064 17.1743H116.255V15.3589V15.3586ZM116.637 27.0458C116.637 22.5827 113.638 19.3299 109.817 19.3299C105.959 19.3299 102.711 22.5827 102.711 27.0458C102.711 31.4518 105.959 34.6478 109.817 34.6478C113.638 34.667 116.637 31.4518 116.637 27.0458H116.637Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M66.9136 26.9512C66.9136 34.232 61.1831 39.5837 54.1533 39.5837C47.1239 39.5837 41.3931 34.2132 41.3931 26.9512C41.3931 19.6327 47.1239 14.2998 54.1533 14.2998C61.1831 14.2998 66.9136 19.6327 66.9136 26.9512ZM61.3358 26.9512C61.3358 22.4128 58.0118 19.2921 54.1533 19.2921C50.2949 19.2921 46.9709 22.4128 46.9709 26.9512C46.9709 31.4519 50.2949 34.6103 54.1533 34.6103C58.0121 34.6103 61.3358 31.4519 61.3358 26.9512Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M94.7841 27.0078C94.7841 34.2886 89.0533 39.6403 82.0238 39.6403C74.9941 39.6403 69.2636 34.2883 69.2636 27.0078C69.2636 19.6893 74.9941 14.3752 82.0238 14.3752C89.0533 14.3752 94.7841 19.6704 94.7841 27.0078ZM89.187 27.0078C89.187 22.4694 85.8633 19.3487 82.0045 19.3487C78.1461 19.3487 74.8221 22.4694 74.8221 27.0078C74.8221 31.5085 78.1461 34.6669 82.0045 34.6669C85.8823 34.6669 89.187 31.4897 89.187 27.0078Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M20.4189 34.0995C12.4149 34.0995 6.14964 27.7075 6.14964 19.7837C6.14964 11.8602 12.4149 5.46819 20.4189 5.46819C24.736 5.46819 27.8877 7.15119 30.2182 9.30715L34.0579 5.50613C30.8106 2.42367 26.4744 0.0782776 20.4189 0.0782776C9.45431 0.0785812 0.227798 8.9292 0.227798 19.7837C0.227798 30.6388 9.45431 39.4891 20.4189 39.4891C26.3407 39.4891 30.8106 37.5602 34.3063 33.9672C37.8974 30.4117 39.0053 25.4192 39.0053 21.3723C39.0053 20.1052 38.8526 18.8004 38.6805 17.836H20.4189V23.0932H33.4275C33.0454 26.384 31.9948 28.6343 30.4475 30.1658C28.5755 32.0383 25.6149 34.0995 20.4189 34.0995Z'
          fill='white'
          fillOpacity='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_217'>
          <rect width='157' height='51' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Glogo
