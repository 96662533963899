import React from "react"

function Slogo() {
  return (
    <svg
      width='194'
      height='49'
      viewBox='0 0 194 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_211)'>
        <path
          d='M61.9864 38.4028L64.4065 32.8112C67.0217 34.7527 70.4955 35.7625 73.9307 35.7625C76.4679 35.7625 78.0684 34.7915 78.0684 33.3162C78.0293 29.2002 62.8841 32.4229 62.767 22.0941C62.728 16.8522 67.4124 12.8139 74.0478 12.8139C77.9903 12.8139 81.9328 13.7845 84.7432 15.9977L82.4792 21.7059C79.903 20.0749 76.7024 18.9102 73.6575 18.9102C71.5887 18.9102 70.2227 19.8808 70.2227 21.1235C70.2617 25.1619 85.5241 22.9486 85.6802 32.8112C85.6802 38.1699 81.1132 41.9365 74.5555 41.9365C69.754 41.9365 65.3432 40.8102 61.9864 38.4028ZM154.849 30.792L161.524 34.4809C159.025 38.9463 154.263 41.9365 148.759 41.9365C140.679 41.9365 134.121 35.4131 134.121 27.3752C134.121 19.3373 140.679 12.8139 148.759 12.8139C154.224 12.8139 159.025 15.8425 161.524 20.2694L154.849 23.958C153.639 21.8612 151.375 20.4247 148.759 20.4247C144.895 20.4247 141.772 23.5309 141.772 27.3752C141.772 31.2194 144.895 34.3256 148.759 34.3256C151.375 34.3256 153.639 32.8888 154.848 30.792H154.849ZM89.0369 0.73764H97.3903V41.3926H89.0369V0.73764ZM164.802 0.73764H173.156V24.618L182.602 13.3962H192.829L181.158 26.9089L193.766 41.3926H183.07L173.156 29.2002V41.3926H164.802V0.73764ZM122.216 30.87V23.9968C121.006 21.9776 118.508 20.4247 115.698 20.4247C111.833 20.4247 108.711 23.5309 108.711 27.3752C108.711 31.2194 111.833 34.3256 115.698 34.3256C118.508 34.3256 121.006 32.85 122.216 30.8696V30.87ZM122.216 13.3966H130.57V41.3541H122.216V38.0538C120.85 40.3447 117.454 41.9368 113.902 41.9368C106.563 41.9368 100.786 35.4134 100.786 27.3367C100.786 19.26 106.563 12.8143 113.902 12.8143C117.454 12.8143 120.85 14.4064 122.216 16.6973V13.3958V13.3966Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M10.3442 30.8308C10.3442 33.6653 8.0412 35.9566 5.19145 35.9566C4.51462 35.9572 3.8443 35.8251 3.21887 35.5677C2.59344 35.3103 2.02517 34.9328 1.54659 34.4567C1.06802 33.9806 0.688531 33.4153 0.429852 32.7931C0.171174 32.1709 0.0383827 31.5041 0.0390805 30.8308C0.0390805 27.9963 2.34207 25.7054 5.19145 25.7054H10.3442V30.8308ZM12.9204 30.8308C12.9204 27.9963 15.2234 25.7054 18.0727 25.7054C20.9221 25.7054 23.2255 27.9963 23.2255 30.8308V43.6447C23.2255 46.4791 20.9225 48.7705 18.0727 48.7705C17.3959 48.7711 16.7257 48.6389 16.1003 48.3815C15.4749 48.1241 14.9067 47.7465 14.4281 47.2704C13.9496 46.7943 13.5701 46.229 13.3115 45.6069C13.0528 44.9847 12.9201 44.3179 12.9208 43.6447V30.8308H12.9204Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M18.0727 10.2512C17.3959 10.2518 16.7256 10.1196 16.1002 9.8622C15.4748 9.6048 14.9066 9.22725 14.4281 8.75115C13.9495 8.27505 13.5701 7.70975 13.3114 7.0876C13.0528 6.46544 12.92 5.79866 12.9207 5.1254C12.9203 2.29094 15.2233 0 18.0727 0C20.9221 0 23.2254 2.29094 23.2254 5.1254V10.2512H18.0727ZM18.0727 12.8527C20.9221 12.8527 23.2254 15.1436 23.2254 17.9781C23.2254 20.8126 20.9224 23.1039 18.0727 23.1039H5.15236C4.47553 23.1045 3.80521 22.9724 3.17978 22.715C2.55435 22.4576 1.98608 22.0801 1.50751 21.604C1.02893 21.1279 0.649446 20.5626 0.390767 19.9404C0.132088 19.3182 -0.000702713 18.6514 -4.89334e-06 17.9781C-4.89334e-06 15.1436 2.30299 12.8531 5.15236 12.8531H18.0731L18.0727 12.8527Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M38.7219 17.9781C38.7219 15.1436 41.0249 12.8527 43.8743 12.8527C46.7237 12.8527 49.027 15.1436 49.027 17.9781C49.027 20.8126 46.724 23.1039 43.8743 23.1039H38.7219V17.9781ZM36.1454 17.9781C36.1454 20.8126 33.8427 23.1039 30.993 23.1039C30.3162 23.1045 29.6458 22.9724 29.0204 22.715C28.395 22.4576 27.8267 22.0801 27.3481 21.604C26.8696 21.1279 26.4901 20.5626 26.2314 19.9404C25.9727 19.3182 25.8399 18.6514 25.8406 17.9781V5.1254C25.8406 2.29094 28.1436 0 30.993 0C33.8424 0 36.1457 2.29094 36.1457 5.1254V17.9785L36.1454 17.9781Z'
          fill='white'
          fillOpacity='0.2'
        />
        <path
          d='M30.993 38.5193C33.8424 38.5193 36.1457 40.8102 36.1457 43.6447C36.1457 46.4791 33.8427 48.7705 30.993 48.7705C30.3162 48.7711 29.6458 48.639 29.0204 48.3816C28.395 48.1242 27.8267 47.7467 27.3481 47.2706C26.8696 46.7945 26.4901 46.2291 26.2314 45.607C25.9727 44.9848 25.8399 44.318 25.8406 43.6447V38.5193H30.993ZM30.993 35.9562C30.3162 35.9568 29.6459 35.8247 29.0205 35.5674C28.3951 35.31 27.8268 34.9325 27.3483 34.4564C26.8697 33.9804 26.4902 33.4151 26.2315 32.793C25.9728 32.1708 25.84 31.5041 25.8406 30.8308C25.8406 27.9963 28.1436 25.7054 30.993 25.7054H43.9137C46.7631 25.7054 49.0661 27.9963 49.0661 30.8308C49.0661 33.6653 46.7631 35.9566 43.9133 35.9566H30.993V35.9562Z'
          fill='white'
          fillOpacity='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_211'>
          <rect width='194' height='49' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Slogo
