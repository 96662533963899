import React from "react"
import Control from "./control"

function Tools2() {
  return (
    <div className='tools2'>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='64'
              height='64'
              viewBox='0 0 64 64'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.78'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.432 58.4356L4.55327 49.8663C2.89826 47.8045 2 45.2692 2 42.6597V11.63C2 7.72128 5.12717 4.47794 9.16143 4.2025L41.0643 2.02437C43.382 1.86612 45.6856 2.48218 47.5895 3.76941L58.7985 11.3478C60.8049 12.7044 62 14.922 62 17.2885V52.5663C62 56.3915 58.9251 59.5586 54.9752 59.8017L19.5667 61.9813C16.4147 62.1754 13.368 60.8474 11.432 58.4356Z'
                  fill='white'
                />
                <path
                  d='M22.4963 27.1574V26.7512C22.4963 25.7214 23.3211 24.8673 24.384 24.7964L32.1267 24.2794L42.8342 40.047V26.2083L40.0781 25.8409V25.6479C40.0781 24.6061 40.9218 23.7464 41.9983 23.6912L49.0433 23.3303V24.3442C49.0433 24.8201 48.6894 25.2272 48.2043 25.3092L46.509 25.596V48.0074L44.3813 48.7391C42.6038 49.3504 40.6249 48.696 39.6073 47.1605L29.2122 31.4744V46.4459L32.4118 47.0583L32.3672 47.355C32.2276 48.2847 31.425 48.9877 30.4541 49.0309L22.4963 49.3851C22.3911 48.3855 23.1403 47.4911 24.1739 47.3825L25.2207 47.2726V27.3105L22.4963 27.1574Z'
                  fill='black'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M41.3499 5.93356L9.44706 8.1117C7.5361 8.24217 6.05481 9.77849 6.05481 11.63V42.6598C6.05481 44.3994 6.65365 46.0896 7.75699 47.4641L14.6357 56.0334C15.7479 57.4189 17.4981 58.1818 19.3088 58.0703L54.7174 55.8907C56.5322 55.779 57.945 54.3239 57.945 52.5663V17.2885C57.945 16.2012 57.3959 15.1823 56.474 14.559L45.265 6.98059C44.1227 6.20825 42.7405 5.83862 41.3499 5.93356ZM11.0291 12.114C10.5853 11.7855 10.7965 11.1011 11.3539 11.0611L41.5645 8.89422C42.5271 8.82518 43.4835 9.09024 44.262 9.64176L50.3235 13.9363C50.5536 14.0993 50.4457 14.4513 50.1608 14.4668L18.1679 16.2067C17.1997 16.2594 16.244 15.9749 15.4741 15.405L11.0291 12.114ZM16.6673 21.6614C16.6673 20.6221 17.5069 19.7635 18.5807 19.7051L52.4062 17.8629C53.4527 17.8059 54.3336 18.6107 54.3336 19.6236V50.1705C54.3336 51.208 53.4969 52.0656 52.4253 52.1266L18.8139 54.0391C17.6493 54.1053 16.6673 53.2104 16.6673 52.083V21.6614Z'
                  fill='black'
                />
              </g>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>Notion</p>
              <p className='tool-in1-txt2'>Free & Paid</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='65'
              height='64'
              viewBox='0 0 65 64'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.78' clipPath='url(#clip0_1_267)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M13.6717 40.2192C13.6724 41.0975 13.4967 41.9674 13.1546 42.779C12.8125 43.5906 12.3108 44.328 11.6781 44.949C11.0454 45.57 10.2942 46.0624 9.46751 46.3979C8.6408 46.7335 7.75481 46.9057 6.86024 46.9046C5.96613 46.905 5.08072 46.7323 4.25464 46.3964C3.42855 46.0605 2.678 45.568 2.04589 44.9471C1.41379 44.3262 0.912528 43.5891 0.570777 42.7778C0.229027 41.9665 0.0534894 41.0971 0.0542014 40.2192C0.053489 39.341 0.229116 38.4714 0.571036 37.6599C0.912955 36.8485 1.41446 36.1112 2.04685 35.4903C2.67924 34.8693 3.43012 34.3769 4.25651 34.0412C5.08291 33.7054 5.96861 33.533 6.86295 33.5337H13.6744V40.2192H13.6717ZM17.0761 40.2192C17.0754 39.341 17.251 38.4714 17.5929 37.6599C17.9348 36.8485 18.4363 36.1112 19.0687 35.4903C19.7011 34.8693 20.452 34.3769 21.2784 34.0412C22.1048 33.7054 22.9905 33.533 23.8848 33.5337C24.7792 33.533 25.6649 33.7054 26.4913 34.0412C27.3177 34.3769 28.0685 34.8693 28.7009 35.4903C29.3333 36.1112 29.8348 36.8485 30.1767 37.6599C30.5187 38.4714 30.6943 39.341 30.6936 40.2192V56.9355C30.6943 57.8136 30.5187 58.6833 30.1767 59.4947C29.8348 60.3062 29.3333 61.0435 28.7009 61.6644C28.0685 62.2853 27.3177 62.7778 26.4913 63.1135C25.6649 63.4492 24.7792 63.6217 23.8848 63.621C22.9905 63.6217 22.1048 63.4492 21.2784 63.1135C20.452 62.7778 19.7011 62.2853 19.0687 61.6644C18.4363 61.0435 17.9348 60.3062 17.5929 59.4947C17.251 58.6833 17.0754 57.8136 17.0761 56.9355V40.2192Z'
                  fill='#E01E5A'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M23.8848 13.3736C22.9902 13.3743 22.1043 13.2018 21.2777 12.8659C20.4512 12.53 19.7002 12.0373 19.0677 11.4161C18.4353 10.7949 17.9339 10.0573 17.5921 9.24554C17.2503 8.43379 17.075 7.56384 17.076 6.68547C17.0753 5.80733 17.251 4.93766 17.5929 4.12623C17.9348 3.31479 18.4363 2.57751 19.0687 1.95657C19.7011 1.33563 20.452 0.843207 21.2784 0.507479C22.1048 0.17175 22.9905 -0.00069732 23.8848 2.11916e-06C24.7791 -0.00069732 25.6648 0.17175 26.4912 0.507479C27.3176 0.843207 28.0685 1.33563 28.7009 1.95657C29.3333 2.57751 29.8348 3.31479 30.1767 4.12623C30.5186 4.93766 30.6943 5.80733 30.6935 6.68547V13.3763H23.8875L23.8848 13.3736ZM23.8848 16.7669C24.7791 16.7662 25.6648 16.9386 26.4912 17.2743C27.3176 17.6101 28.0685 18.1025 28.7009 18.7234C29.3333 19.3444 29.8348 20.0817 30.1767 20.8931C30.5186 21.7045 30.6943 22.5742 30.6935 23.4523C30.6943 24.3305 30.5186 25.2001 30.1767 26.0116C29.8348 26.823 29.3333 27.5603 28.7009 28.1812C28.0685 28.8022 27.3176 29.2946 26.4912 29.6303C25.6648 29.9661 24.7791 30.1385 23.8848 30.1378H6.80875C5.91464 30.1385 5.02916 29.9662 4.20294 29.6306C3.37672 29.295 2.62597 28.8028 1.99361 28.1822C1.36125 27.5615 0.859694 26.8246 0.517615 26.0134C0.175536 25.2023 -0.000354061 24.3329 2.15823e-06 23.455C-0.000710178 22.5769 0.174917 21.7072 0.516837 20.8958C0.858756 20.0843 1.36026 19.347 1.99265 18.7261C2.62504 18.1052 3.37592 17.6127 4.20231 17.277C5.02871 16.9413 5.91441 16.7688 6.80875 16.7695H23.8875L23.8848 16.7669Z'
                  fill='#36C5F0'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M51.1738 23.4523C51.1731 22.574 51.3488 21.7041 51.6909 20.8925C52.0329 20.0809 52.5347 19.3435 53.1674 18.7225C53.8 18.1015 54.5512 17.6092 55.378 17.2736C56.2047 16.938 57.0907 16.7658 57.9852 16.7669C58.8796 16.7662 59.7653 16.9386 60.5917 17.2743C61.4181 17.6101 62.1689 18.1025 62.8013 18.7234C63.4337 19.3444 63.9352 20.0817 64.2771 20.8931C64.6191 21.7045 64.7947 22.5742 64.794 23.4523C64.7947 24.3305 64.6191 25.2002 64.2771 26.0116C63.9352 26.823 63.4337 27.5603 62.8013 28.1812C62.1689 28.8022 61.4181 29.2946 60.5917 29.6303C59.7653 29.9661 58.8796 30.1385 57.9852 30.1378H51.1738V23.455V23.4523ZM47.7694 23.4523C47.7701 24.3305 47.5945 25.2002 47.2526 26.0116C46.9106 26.823 46.4091 27.5603 45.7767 28.1812C45.1444 28.8022 44.3935 29.2946 43.5671 29.6303C42.7407 29.9661 41.855 30.1385 40.9606 30.1378C40.0661 30.1389 39.1801 29.9667 38.3534 29.6311C37.5267 29.2955 36.7755 28.8032 36.1428 28.1822C35.5101 27.5612 35.0084 26.8238 34.6663 26.0122C34.3242 25.2006 34.1485 24.3307 34.1492 23.4523V6.68548C34.1485 5.80711 34.3242 4.93722 34.6663 4.12561C35.0084 3.31401 35.5101 2.57661 36.1428 1.95563C36.7755 1.33465 37.5267 0.842289 38.3534 0.506717C39.1801 0.171145 40.0661 -0.00104452 40.9606 4.76684e-06C41.855 -0.000694672 42.7407 0.171753 43.5671 0.507481C44.3935 0.84321 45.1444 1.33563 45.7767 1.95657C46.4091 2.57752 46.9106 3.3148 47.2526 4.12623C47.5945 4.93766 47.7701 5.80733 47.7694 6.68548V23.455V23.4523Z'
                  fill='#2EB67D'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M40.9606 50.2474C41.8549 50.2467 42.7406 50.4191 43.567 50.7548C44.3934 51.0906 45.1443 51.583 45.7767 52.2039C46.4091 52.8249 46.9106 53.5622 47.2525 54.3736C47.5944 55.185 47.7701 56.0547 47.7693 56.9328C47.7704 57.8112 47.5951 58.6812 47.2533 59.4929C46.9115 60.3046 46.4101 61.0422 45.7777 61.6635C45.1452 62.2847 44.3942 62.7773 43.5677 63.1132C42.7411 63.4491 41.8552 63.6217 40.9606 63.621C40.066 63.6217 39.1801 63.4491 38.3535 63.1132C37.527 62.7773 36.776 62.2847 36.1435 61.6635C35.5111 61.0422 35.0097 60.3046 34.6679 59.4929C34.3261 58.6812 34.1508 57.8112 34.1518 56.9328V50.2474H40.9606ZM40.9606 46.9046C40.0663 46.9053 39.1806 46.7329 38.3542 46.3972C37.5278 46.0614 36.7769 45.569 36.1445 44.9481C35.5121 44.3271 35.0106 43.5898 34.6687 42.7784C34.3268 41.967 34.1511 41.0973 34.1518 40.2192C34.1511 39.341 34.3268 38.4714 34.6687 37.6599C35.0106 36.8485 35.5121 36.1112 36.1445 35.4903C36.7769 34.8693 37.5278 34.3769 38.3542 34.0412C39.1806 33.7054 40.0663 33.533 40.9606 33.5337H58.0366C58.931 33.533 59.8167 33.7054 60.6431 34.0412C61.4695 34.3769 62.2203 34.8693 62.8527 35.4903C63.4851 36.1112 63.9866 36.8485 64.3286 37.6599C64.6705 38.4714 64.8461 39.341 64.8454 40.2192C64.8461 41.0973 64.6705 41.967 64.3286 42.7784C63.9866 43.5898 63.4851 44.3271 62.8527 44.9481C62.2203 45.569 61.4695 46.0614 60.6431 46.3972C59.8167 46.7329 58.931 46.9053 58.0366 46.9046H40.9606Z'
                  fill='#ECB22E'
                />
              </g>
              <defs>
                <clipPath id='clip0_1_267'>
                  <rect width='65' height='63.8231' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>Slack</p>
              <p className='tool-in1-txt2'>Free & Paid</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='65'
              height='64'
              viewBox='0 0 65 64'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.78' clipPath='url(#clip0_1_239)'>
                <path
                  d='M0 4C0 1.79086 1.79086 0 4 0H61C63.2091 0 65 1.79086 65 4V59.8234C65 62.0325 63.2091 63.8234 61 63.8234H4C1.79086 63.8234 0 62.0325 0 59.8234V4Z'
                  fill='#FF0066'
                />
                <path
                  d='M22.1178 20.5381C24.1737 20.5087 25.819 18.8536 25.7992 16.8346C25.7792 14.8155 24.1016 13.192 22.0452 13.2017C19.9888 13.2114 18.3273 14.8506 18.327 16.8698C18.3287 17.3556 18.4283 17.8363 18.6201 18.2842C18.8119 18.732 19.092 19.1382 19.4444 19.4792C19.7968 19.8202 20.2145 20.0894 20.6733 20.2712C21.1321 20.453 21.6231 20.5438 22.1178 20.5384V20.5381Z'
                  fill='white'
                />
                <path
                  d='M14.258 40.178C14.0429 41.0813 13.9305 42.0053 13.9229 42.9329C13.9229 46.1622 15.7063 48.3065 19.4971 48.3065C22.6405 48.3065 25.191 46.4736 27.0242 43.5123L25.9034 47.9288H32.1434L35.7134 33.8877C36.6051 30.3288 38.3314 28.4832 40.9522 28.4832C43.0139 28.4832 44.2959 29.7417 44.2959 31.8224C44.3061 32.4893 44.2121 33.154 44.0176 33.7932L42.1793 40.2496C41.9164 41.1387 41.7852 42.0604 41.7896 42.9863C41.7896 46.0498 43.6279 48.2966 47.473 48.2966C50.7621 48.2966 53.383 46.2183 54.8312 41.2376L52.3793 40.3057C51.1521 43.6449 50.0941 44.2473 49.2593 44.2473C48.4247 44.2473 47.9748 43.7013 47.9748 42.6058C47.9989 42.0311 48.0923 41.4612 48.253 40.908L50.0393 34.6154C50.4403 33.3216 50.6471 31.9773 50.653 30.625C50.653 25.9173 47.7539 23.4615 44.2413 23.4615C40.9522 23.4615 37.6088 26.3742 35.9368 29.4405L37.1614 23.9362H27.6323L26.2934 28.7868H30.7551L28.0096 39.5705C25.8514 44.2782 21.8892 44.3547 21.3925 44.245C20.576 44.0635 20.056 43.7599 20.056 42.713C20.0799 41.8656 20.2109 41.0244 20.446 40.2087L24.6268 23.9235H14.0397L12.7034 28.7741H17.1051L14.258 40.178Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_1_239'>
                  <rect width='65' height='63.8234' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>Invision</p>
              <p className='tool-in1-txt2'>Free & Paid</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
    </div>
  )
}

export default Tools2
