import React from "react"
import Logos from "./logos"

function Screen3() {
  return (
    <div className='screen3'>
      <p className="brand-txt">Trusted more than 150+ brand</p>
      <Logos />
    </div>
  )
}

export default Screen3
