import React from "react"

function Regist() {
  return (
    <div className='header-regist'>
      <button className="login-btn" type='button'>Login</button>
      <button className="signup-btn" type='button'>Sign Up</button>
    </div>
  )
}

export default Regist
