import React from "react"
import Control from "./control"

function Tools1() {
  return (
    <div className='tools1'>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='64'
              height='64'
              viewBox='0 0 64 64'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.78'>
                <path
                  d='M42.6667 42.6667C48.5577 42.6667 53.3333 37.8911 53.3333 32C53.3333 26.109 48.5577 21.3334 42.6667 21.3334C36.7756 21.3334 32 26.109 32 32C32 37.8911 36.7756 42.6667 42.6667 42.6667Z'
                  fill='#19BCFE'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.3334 64C24.1624 64 26.8755 62.8761 28.8759 60.8758C30.8763 58.8754 32.0001 56.1623 32.0001 53.3333V42.6666H21.3334C18.5044 42.6666 15.7913 43.7904 13.7909 45.7908C11.7906 47.7912 10.6667 50.5043 10.6667 53.3333C10.6667 56.1623 11.7906 58.8754 13.7909 60.8758C15.7913 62.8761 18.5044 64 21.3334 64Z'
                  fill='#09CF83'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.3334 42.6667H32.0001V21.3334H21.3334C18.5044 21.3334 15.7913 22.4572 13.7909 24.4576C11.7906 26.458 10.6667 29.1711 10.6667 32C10.6667 34.829 11.7906 37.5421 13.7909 39.5425C15.7913 41.5429 18.5044 42.6667 21.3334 42.6667Z'
                  fill='#A259FF'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.3334 21.3333H32.0001V0H21.3334C18.5044 0 15.7913 1.12381 13.7909 3.12419C11.7906 5.12458 10.6667 7.83769 10.6667 10.6667C10.6667 13.4956 11.7906 16.2088 13.7909 18.2091C15.7913 20.2095 18.5044 21.3333 21.3334 21.3333Z'
                  fill='#F24E1E'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M42.6667 21.3333H32V0H42.6667C45.4956 0 48.2087 1.12381 50.2091 3.12419C52.2095 5.12458 53.3333 7.83769 53.3333 10.6667C53.3333 13.4956 52.2095 16.2088 50.2091 18.2091C48.2087 20.2095 45.4956 21.3333 42.6667 21.3333Z'
                  fill='#FF7262'
                />
              </g>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>FIGMA</p>
              <p className='tool-in1-txt2'>Free</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='64'
              height='57'
              viewBox='0 0 64 57'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_1_327)'>
                <path
                  d='M32 0L13.9637 1.87248L0 20.2781L32 56.867L64 20.2779L50.0365 1.87248L32 0Z'
                  fill='#FDB300'
                />
                <path
                  d='M0 20.2782L32 56.8671L12.9615 20.2782H0Z'
                  fill='#EB6C00'
                />
                <path
                  d='M51.0382 20.2782L32 56.8671L64 20.2782H51.0382Z'
                  fill='#EB6C00'
                />
                <path
                  d='M12.9617 20.2782L32.0002 56.8671L51.0387 20.2782H12.9617Z'
                  fill='#FDAD00'
                />
                <path
                  d='M13.9636 1.87248L12.9611 20.2781L31.9998 0L13.9636 1.87248Z'
                  fill='#FDD231'
                />
                <path
                  d='M51.0382 20.2781L50.0363 1.87248L32 0L51.0382 20.2781Z'
                  fill='#FDD231'
                />
                <path
                  d='M51.0383 20.2781H63.9998L50.0363 1.87244L51.0383 20.2781Z'
                  fill='#FDAD00'
                />
                <path
                  d='M0 20.2781H12.9615L13.9637 1.87244L0 20.2781Z'
                  fill='#FDAD00'
                />
                <path
                  d='M32.0002 0L12.9617 20.2779H51.0387L32.0002 0Z'
                  fill='#FEEEB7'
                />
              </g>
              <defs>
                <clipPath id='clip0_1_327'>
                  <rect width='64' height='56.95' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>Sketch</p>
              <p className='tool-in1-txt2'>Trial & Paid</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
      <div className='tool'>
        <div className='tool-in'>
          <div className='tool-in1'>
            <svg
              width='65'
              height='63'
              viewBox='0 0 65 63'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.78'>
                <mask
                  id='mask0_1_390'
                  style={{ maskType: "alpha" }}
                  maskUnits='userSpaceOnUse'
                  x='0'
                  y='0'
                  width='65'
                  height='63'
                >
                  <path
                    d='M45.9135 62.497C46.4286 62.6927 46.9797 62.7831 47.5322 62.7624C48.0848 62.7417 48.6271 62.6104 49.1254 62.3767L62.4557 56.1267C63.1422 55.8046 63.7215 55.3002 64.1269 54.6715C64.5323 54.0429 64.7473 53.3154 64.7472 52.573V10.1931C64.7473 9.45065 64.5323 8.72327 64.1269 8.09463C63.7215 7.46599 63.1422 6.96164 62.4557 6.63958L49.1256 0.389333C48.3724 0.0361606 47.5251 -0.0801242 46.7011 0.0565747C45.877 0.193274 45.117 0.576195 44.5261 1.15234L19.0068 23.8383L7.89074 15.6165C7.38932 15.2456 6.77047 15.0575 6.14158 15.0847C5.51268 15.112 4.91342 15.3529 4.44777 15.7657L0.882669 18.9258C-0.292917 19.9677 -0.294186 21.7698 0.879622 22.8133L10.5197 31.383L0.879622 39.9525C-0.294186 40.9963 -0.292917 42.7981 0.882669 43.8402L4.44777 47.0001C4.91339 47.4129 5.51264 47.6539 6.14153 47.6812C6.77043 47.7085 7.38929 47.5204 7.89074 47.1495L19.0068 38.9275L44.5261 61.6135C44.9207 61.9985 45.3931 62.2994 45.9135 62.497ZM48.5703 17.0609L29.2069 31.383L48.5703 45.7049V17.0609Z'
                    fill='white'
                  />
                </mask>
                <g mask='url(#mask0_1_390)'>
                  <path
                    d='M62.4953 6.64928L49.1545 0.390373C48.4011 0.036915 47.5535 -0.0796175 46.7291 0.0569015C45.9048 0.193421 45.1443 0.576241 44.553 1.15239L0.880099 39.9525C-0.294472 40.9963 -0.292948 42.7982 0.883145 43.8403L4.45053 47.0002C4.91657 47.4131 5.51619 47.654 6.14545 47.6813C6.77471 47.7086 7.39394 47.5205 7.89578 47.1496L60.4879 8.27301C62.2523 6.96893 64.7865 8.19508 64.7865 10.353V10.202C64.7865 9.45976 64.5715 8.73254 64.1661 8.10404C63.7608 7.47554 63.1817 6.97129 62.4953 6.64928Z'
                    fill='#0065A9'
                  />
                  <path
                    d='M62.4953 56.1159L49.1545 62.3749C48.4011 62.7282 47.5535 62.8447 46.7292 62.7082C45.9048 62.5717 45.1443 62.1889 44.553 61.6128L0.880099 22.8127C-0.294472 21.7691 -0.292948 19.967 0.883145 18.9252L4.45053 15.7651C4.9166 15.3522 5.51624 15.1113 6.1455 15.084C6.77476 15.0568 7.39397 15.2449 7.89578 15.6159L60.4879 54.4922C62.2523 55.7963 64.7865 54.5701 64.7865 52.412V52.5632C64.7865 53.3055 64.5714 54.0327 64.1661 54.6612C63.7608 55.2897 63.1816 55.7939 62.4953 56.1159Z'
                    fill='#007ACC'
                  />
                  <path
                    d='M49.1126 62.3798C48.359 62.733 47.5114 62.8493 46.6869 62.7127C45.8625 62.5761 45.102 62.1932 44.5106 61.617C46.0038 63.0718 48.5573 62.0413 48.5573 59.9834V2.78904C48.5573 0.731112 46.0038 -0.299339 44.5106 1.15566C45.102 0.57947 45.8624 0.196552 46.6869 0.059863C47.5113 -0.0768265 48.359 0.039466 49.1126 0.392659L62.4511 6.64291C63.1379 6.96474 63.7175 7.46901 64.1231 8.09767C64.5287 8.72634 64.7439 9.45383 64.7439 10.1964V52.5765C64.7439 54.0919 63.8527 55.4734 62.4511 56.13L49.1126 62.38V62.3798Z'
                    fill='#1F9CF0'
                  />
                  <path
                    d='M45.9135 62.4964C46.4287 62.6921 46.9797 62.7824 47.5322 62.7618C48.0848 62.7411 48.6271 62.6098 49.1254 62.3762L62.4554 56.1259C63.142 55.8039 63.7214 55.2996 64.1268 54.6709C64.5322 54.0423 64.7473 53.3149 64.7472 52.5724V10.1925C64.7473 9.45009 64.5323 8.72271 64.1269 8.09407C63.7215 7.46543 63.1422 6.96107 62.4557 6.63902L49.1251 0.388521C48.3719 0.0354046 47.5247 -0.0808494 46.7007 0.0558482C45.8767 0.192546 45.1167 0.575435 44.5259 1.15152L19.0068 23.8378L7.89074 15.6159C7.38932 15.2451 6.77047 15.0569 6.14158 15.0842C5.51268 15.1114 4.91342 15.3523 4.44777 15.7651L0.882669 18.9253C-0.292917 19.9671 -0.294186 21.7692 0.879622 22.8128L10.5197 31.3825L0.879622 39.9519C-0.294186 40.9957 -0.292917 42.7976 0.882669 43.8397L4.44777 46.9995C4.91339 47.4124 5.51264 47.6533 6.14153 47.6806C6.77043 47.7079 7.38929 47.5198 7.89074 47.149L19.0068 38.9269L44.5259 61.6129C44.9205 61.998 45.393 62.2989 45.9135 62.4964ZM48.5703 17.0601L29.2069 31.3825L48.5703 45.7044V17.0603V17.0601Z'
                    fill='url(#paint0_linear_1_390)'
                    fillOpacity='0.25'
                  />
                </g>
              </g>
              <defs>
                <linearGradient
                  id='paint0_linear_1_390'
                  x1='32.3737'
                  y1='0.00012207'
                  x2='32.3737'
                  y2='62.7647'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='white' />
                  <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
              </defs>
            </svg>
            <div className='tool-in1-txt'>
              <p className='tool-in1-txt1'>Visual Studio Code</p>
              <p className='tool-in1-txt2'>Free</p>
            </div>
          </div>
          <p className='tool-in2-txt'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Control />
        </div>
      </div>
    </div>
  )
}

export default Tools1
