import React from "react"
import Left from "./newcomer/left"
import Right from "./newcomer/tools"

function Screen4() {
  return (
    <div className='screen4'>
      <Left />
      <Right />
    </div>
  )
}

export default Screen4
